import axios from 'axios';
import store, { SET_LOADER, DELETE_LOADER } from '../../store';
import validation from '../_utils/validation';
import logout from '../auth-account/logout';
import reissue from '../auth-account/reissue';

// ----------------------------------------------------------------------

const instance = axios.create({ baseURL: '/api/' });

instance.interceptors.request.use(async (config) => {
  const url = config.url.split('/')[0];
  if ( url !== 'dashboards' ) store.dispatch( SET_LOADER() );
  
  const token = store.getState().token;
  config.headers.Authorization = token ? `Bearer ${token}` : null;
  
  if ( !validation(token) ) await reissue(config);

  return config;
});

instance.interceptors.response.use(
  (response) => {
    const url = response.config.url.split('/')[0];
    if ( url !== 'dashboards' ) store.dispatch( DELETE_LOADER() );
    return response;
  },
  async (error) => {
    const config = error.config;
    const url = config.url.split('/')[0];
    const code = error.response.data.code;

    if ( url !== 'dashboards' ) store.dispatch( DELETE_LOADER() );

    if ( code === 'ERR-003' ) await reissue(config);

    if ( config.url === 'accounts/me' ) return logout();
    if ( config.url === 'auth/logout' || code === 'ERR-001' ) return window.location.href = '/';

    return Promise.reject(error);
  }
);

export default instance;
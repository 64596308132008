import axios from '../_utils/axios';

// ----------------------------------------------------------------------

const updateVolume = async (volume: number) => {
  return await axios.put('dashboards/dosing-pump', {volume})
    .catch((error) => {
      const code = error.response.data.code;

      let message = '염소를 주입할 수 없습니다.';
      switch( code ) {
        case 'ERR-103':
          message = '잠시 후 다시 시도해 주세요.'
          break;
        case 'ERR-107':
          message = '현재 진행중인 시험이 없습니다.'
      }

      return Promise.reject(
        new Error(message)
      );
    });
};

export default updateVolume;
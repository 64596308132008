import { useState, useEffect } from 'react';
// store
import { useSelector, useDispatch, SET_LOADER, DELETE_LOADER } from '../store';
// hooks
import useInterval from '../hooks/useInterval';
import useResponsive from '../hooks/useResponsive';
// components
import Breadcrumbs from '../components/breadcrumbs';
import { TextField, TextareaField, SelectField, ButtonField } from '../components/hook-form';
import Dialog from '../components/dialog';
import Loaders from '../components/loaders';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// styles
import { DashboardContainer, Dashboard, DashboardContent, ContentBox, Controls, ButtonWrapper, PrimaryButton, DefaultButton } from '../styles';
// apis
import { getStatus, getData, start, stop, updateFan, updateVolume } from '../apis';

// ----------------------------------------------------------------------

const schema = yup.object().shape({
  testCase: yup.string()
    .required()
    .max(30, '30자 이내로 입력해 주세요.'),
  memo: yup.string()
    .trim()
    .max(50, '50자 이내로 입력해 주세요.'),
  fanWindSpeed: yup.string()
    .required(),
  pumpStatus: yup.string()
    .required(),
  volume: yup.number()
});

const fanWindSpeed: {[key: string]: string} = { 'OFF': '정지', 'STEP_1': '1단계', 'STEP_2': '2단계', 'STEP_3': '3단계', 'STEP_4': '4단계', 'STEP_5': '5단계', 'STEP_6': '6단계' }
  
const delay: any = 10000;

const defaultStatus: {
  listId: number;
  testCase: string;
  memo: string;
  testStartTime: string;
  fanWindSpeed: string;
  settingVolume: number;
} = {
  listId: null,
  testCase: null,
  memo: null,
  testStartTime: null,
  fanWindSpeed: null,
  settingVolume: null
}

const defaultData: {
  listId: number,
  ph: number,
  ec: number,
  turbidity: number,
  waterTemp: number,
  temp: number,
  humidity: number,
  rc: number,
  chlorineGas1: number,
  chlorineGas2: number,
  indoorTemp: number,
  indoorHumidity: number,
  exoPh: number,
  exoOrp: number,
  exoDo: number,
  exoEc: number,
  exoTurbidity: number,
  exoWaterTemp: number,
  fanWindSpeed: string,
  lastVolume: number,
  acquisitionAt : string
} = {
  listId: null,
  ph: null,
  ec: null,
  turbidity: null,
  waterTemp: null,
  temp: null,
  humidity: null,
  rc: null,
  chlorineGas1: null,
  chlorineGas2: null,
  indoorTemp: null,
  indoorHumidity: null,
  exoPh: null,
  exoOrp: null,
  exoDo: null,
  exoEc: null,
  exoTurbidity: null,
  exoWaterTemp: null,
  fanWindSpeed: null,
  lastVolume: null,
  acquisitionAt : null
};

// ----------------------------------------------------------------------

function DashboardPage() {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.account.role) === 'ADMIN';
  const mobile = useResponsive('down', 'md');

  const loader = useSelector((state) => state.loader);

  const {
    control,
    setValue,
    getValues,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      testCase: '',
      memo: '',
      fanWindSpeed: 'OFF',
      pumpStatus: 'OFF',
      volume: 1
    }
  });

  const [ loading, setLoading ] = useState(false);

  const [ state, setState ] = useState(false);
  const [ status, setStatus ] = useState(defaultStatus);
  const [ data, setData ] = useState(defaultData);
  
  const [ dialog, setDialog ] = useState({ visible: false, type: null, title: null, text: null, action: null });
  const [ message, setMessage ] = useState(null);
  const [ fan, setFan ] = useState(null);
  const [ volume, setVolume ] = useState(null);

  const [ controls, setControls ] = useState(false);

  const handleClose = () => setDialog({ visible: false, type: null, title: null, text: null, action: null });
  const handleFan = () => setFan(null);
  const handleVolume = () => setVolume(null);
  const handleMessage = () => setMessage(null);

  const handleControls = () => setControls(null);

  useEffect(() => {
    dispatch( SET_LOADER() );
    updateDatesets();
  }, []);

  useEffect(() => {
    if ( !admin ) {
      ( async () => await getStatus(setStatus) )();
    }
    if ( !data.listId ) setStatus(defaultStatus);
  }, [admin, data.listId]);

  useEffect(() => {
    if ( loader ) dispatch( DELETE_LOADER() );

    if ( status.listId ) {
      setState(true);
    } else {
      setState(false);
    }
    setValue('testCase', status.testCase || '');
    setValue('memo', status.memo || '');
    setValue('fanWindSpeed', status.fanWindSpeed || 'OFF');
    setValue('volume', status.settingVolume || 1);
  }, [status, loader]);

  useInterval(() => {
    updateDatesets();
  }, delay);

  const onStart = async () => {
    setDialog({ visible: true, type: 'start', title: '시험 시작', text: '시험을 시작하시겠습니까?', action: '시작' });
    if ( mobile ) handleControls();
  };

  const onStop = () => {
    setDialog({ visible: true, type: 'stop', title: '시험 종료', text: '시험을 종료하시겠습니까?', action: '종료' });
    if ( mobile ) handleControls();
  };

  const updateDatesets = async () => {
    await getData(setData)
      .then((response) => {
        if ( admin && response ) getStatus(setStatus);
      });
  };

  const handleActions = async () => {
    setLoading(true);
    await action()
      .then(() => updateDatesets())
      .catch((error) => setMessage(error.message))
      .finally(() => {
        handleClose();
        setLoading(false);
      });
  };

  const action = async () => {
    if ( dialog.type === 'start' ) {
      const values = getValues();
      await start(values.testCase, values.memo === '' ? null : values.memo, values.fanWindSpeed);
    } else if ( dialog.type === 'stop' ) {
      await stop();
    }
  };

  if ( !loader ) return (
    <DashboardContainer>
      { loading &&
        <Loaders />
      }
      <Dashboard>
        <Breadcrumbs heading='대시보드' />

        { admin && mobile &&
          <PrimaryButton onClick={() => setControls(true)}>시험 설정</PrimaryButton>
        }

        <DashboardContent>
          <div>
            <p>테스트 케이스</p>
            <span>{ status.testCase || '-' }</span>
          </div>
          <div>
            <p>시험 시작 시간</p>
            <span>{ status.testStartTime?.replace('T', ' ') || '-' }</span>
          </div>
          <div>
            <p>실내 온도</p>
            <span>{ data.indoorTemp !== null ? data.indoorTemp + ' ℃' : '-' }</span>
          </div>
          <div>
            <p>실내 습도</p>
            <span>{ data.indoorHumidity !== null ? data.indoorHumidity + ' %' : '-' }</span>
          </div>
          <div>
            <p>업데이트 시간</p>
            <span>{ data.acquisitionAt ? data.acquisitionAt.replace('T', ' ') : '-' }</span>
          </div>
        </DashboardContent>

        <div>
          <ContentBox>
            <p>물탱크</p>

            <div>
              <p>수소이온농도</p>
              <p>{ data.ph !== null ? data.ph + ' pH' : '-' }</p>
            </div>
            
            <div>
              <p>전기전도도</p>
              <p>{ data.ec !== null ? data.ec + ' ㎲/㎝' : '-' }</p>
            </div>
            
            <div>
              <p>탁도</p>
              <p>{ data.turbidity !== null ? data.turbidity + ' FNU' : '-' }</p>
            </div>

            <div>
              <p>수온</p>
              <p>{ data.waterTemp !== null ? data.waterTemp + ' ℃' : '-' }</p>
            </div>

            <div>
              <p>온도</p>
              <p>{ data.temp !== null ? data.temp + ' ℃' : '-' }</p>
            </div>

            <div>
              <p>습도</p>
              <p>{ data.humidity !== null ? data.humidity + ' %' : '-' }</p>
            </div>

            <div>
              <p>잔류염소</p>
              <p>{ data.rc !== null ? data.rc + ' ppm' : '-' }</p>
            </div>

            <div>
              <p>염소가스 1</p>
              <p>{ data.chlorineGas1 !== null ? data.chlorineGas1 + ' ppm' : '-' }</p>
            </div>

            <div>
              <p>염소가스 2</p>
              <p>{ data.chlorineGas2 !== null ? data.chlorineGas2 + ' ppm' : '-' }</p>
            </div>

            <div>
              <p>환풍기 풍속</p>
              <p>{ data.fanWindSpeed !== null ? fanWindSpeed[data.fanWindSpeed] : '-' }</p>
            </div>

            <div>
              <p>마지막 염소 주입량</p>
              <p>{ data.lastVolume !== null ? data.lastVolume + ' ml' : '-' }</p>
            </div>
          </ContentBox>

          <ContentBox>
            <p>EXO</p>

            <div>
              <p>수소이온농도</p>
              <p>{ data.exoPh !== null ? data.exoPh + ' pH' : '-' }</p>
            </div>

            <div>
              <p>산화환원전위</p>
              <p>{ data.exoOrp !== null ? data.exoOrp + ' mV' : '-' }</p>
            </div>

            <div>
              <p>용존산소량</p>
              <p>{ data.exoDo !== null ? data.exoDo + ' ㎎/L' : '-' }</p>
            </div>
            
            <div>
              <p>전기전도도</p>
              <p>{ data.exoEc !== null ? data.exoEc + ' ㎲/㎝' : '-' }</p>
            </div>
            
            <div>
              <p>탁도</p>
              <p>{ data.exoTurbidity !== null ? data.exoTurbidity + ' FNU' : '-' }</p>
            </div>

            <div>
              <p>수온</p>
              <p>{ data.exoWaterTemp !== null ? data.exoWaterTemp + ' ℃' : '-' }</p>
            </div>
          </ContentBox>
        </div>
      </Dashboard>

      { admin && !mobile &&
        <Controls>
          <p>시험 설정</p>

          <form onSubmit={ handleSubmit(onStart) }>
            <TextField name='testCase' control={control} label='테스트 케이스' type='text' disabled={state} />

            <TextareaField name='memo' control={control} label='메모' disabled={state} />

            <SelectField
              name='fanWindSpeed'
              label='환풍기 풍속'
              option={[
                {value: 'OFF', name: '정지'},
                {value: 'STEP_1', name: '1단계'},
                {value: 'STEP_2', name: '2단계'},
                {value: 'STEP_3', name: '3단계'},
                {value: 'STEP_4', name: '4단계'},
                {value: 'STEP_5', name: '5단계'},
                {value: 'STEP_6', name: '6단계'}
              ]}
              control={control}
              disabled={state}
              action={
                <DefaultButton type='button' disabled={!state} onClick={() => setFan(true)}>변경</DefaultButton>
              }
            />

            <ButtonField
              name='volume'
              label='염소 주입량 (ml)'
              control={control}
              disabled={true}
              action={
                <DefaultButton type='button' disabled={!state} onClick={() => setVolume(true)}>주입</DefaultButton>
              }
            />

            <ButtonWrapper>
              <PrimaryButton type='submit' disabled={state}>시작</PrimaryButton>
              <PrimaryButton type='button' disabled={!state} onClick={() => onStop()}>종료</PrimaryButton>
            </ButtonWrapper>
          </form>
        </Controls>
      }

      { dialog.visible &&
        <Dialog
          title={ dialog.title }
          handleClose={handleClose}
        >
          <p>{ dialog.text }</p>
          <ButtonWrapper>
            <PrimaryButton onClick={() => handleActions()}>{ dialog.action }</PrimaryButton>
            <DefaultButton onClick={() => handleClose()}>취소</DefaultButton>
          </ButtonWrapper>
        </Dialog>
      }

      { fan &&
        <FanDialog value={getValues('fanWindSpeed')} handleFan={handleFan} updateDatesets={updateDatesets} setMessage={setMessage} setLoading={setLoading} />
      }

      { volume &&
        <VolumeDialog value={getValues('volume')} handleVolume={handleVolume} updateDatesets={updateDatesets} setMessage={setMessage} setLoading={setLoading} />
      }

      { message &&
        <Dialog
          title=''
          handleClose={handleMessage}
        >
          <p>{ message }</p>
          <PrimaryButton onClick={() => handleMessage()}>확인</PrimaryButton>
        </Dialog>
      }

      { controls &&
        <Dialog
          title='시험 설정'
          handleClose={handleControls}
        >
          <form onSubmit={ handleSubmit(onStart) }>
            <TextField name='testCase' control={control} label='테스트 케이스' type='text' disabled={state} />

            <TextareaField name='memo' control={control} label='메모' disabled={state} />

            <SelectField
              name='fanWindSpeed'
              label='환풍기 풍속'
              option={[
                {value: 'OFF', name: '정지'},
                {value: 'STEP_1', name: '1단계'},
                {value: 'STEP_2', name: '2단계'},
                {value: 'STEP_3', name: '3단계'},
                {value: 'STEP_4', name: '4단계'},
                {value: 'STEP_5', name: '5단계'},
                {value: 'STEP_6', name: '6단계'}
              ]}
              control={control}
              disabled={state}
              action={
                <DefaultButton type='button' disabled={!state} onClick={() => {
                  setFan(true);
                  handleControls();
                }}>
                  변경
                </DefaultButton>
              }
            />

            <ButtonField
              name='volume'
              label='염소 주입량 (ml)'
              control={control}
              disabled={true}
              action={
                <DefaultButton type='button' disabled={!state} onClick={() => {
                  setVolume(true);
                  handleControls();
                }}>
                  주입
                </DefaultButton>
              }
            />

            <ButtonWrapper>
              <PrimaryButton type='submit' disabled={state}>시작</PrimaryButton>
              <PrimaryButton type='button' disabled={!state} onClick={() => onStop()}>종료</PrimaryButton>
            </ButtonWrapper>
          </form>
        </Dialog>
      }
    </DashboardContainer>
  );
}

export default DashboardPage;

// ----------------------------------------------------------------------

interface Props {
  value: string;
  handleFan: () => void;
  setMessage: React.Dispatch<any>;
  setLoading: React.Dispatch<any>;
  updateDatesets: () => Promise<void>;
};

export function FanDialog({ value, handleFan, updateDatesets, setMessage, setLoading }: Props) {

  const {
    control,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        fanWindSpeed: yup.string()
          .required()
      })
    ),
    defaultValues: {
      fanWindSpeed: value
    }
  });

  const onSubmit = async (data: { fanWindSpeed?: string }) => {
    setLoading(true);
    await updateFan(data.fanWindSpeed)
      .then(() => updateDatesets())
      .catch((error) => setMessage(error.message))
      .finally(() => {
        handleFan();
        setLoading(false);
      });
  };

  return (
    <Dialog
      title='환풍기 풍속 변경'
      handleClose={handleFan}
    >
      <form onSubmit={ handleSubmit(onSubmit) }>
        <SelectField
          name='fanWindSpeed'
          label='환풍기 풍속'
          option={[
            {value: 'OFF', name: '정지'},
            {value: 'STEP_1', name: '1단계'},
            {value: 'STEP_2', name: '2단계'},
            {value: 'STEP_3', name: '3단계'},
            {value: 'STEP_4', name: '4단계'},
            {value: 'STEP_5', name: '5단계'},
            {value: 'STEP_6', name: '6단계'}
          ]}
          control={control}
        />

        <ButtonWrapper>
          <PrimaryButton type='submit'>변경</PrimaryButton>
          <DefaultButton type='button' onClick={() => handleFan()}>취소</DefaultButton>
        </ButtonWrapper>
      </form>
    </Dialog>
  );
}

// ----------------------------------------------------------------------

interface VolumeProps {
  value: number;
  handleVolume: () => void;
  setMessage: React.Dispatch<any>;
  setLoading: React.Dispatch<any>;
  updateDatesets: () => Promise<void>;
};

export function VolumeDialog({ value, handleVolume, updateDatesets, setMessage, setLoading }: VolumeProps) {

  const {
    control,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        volume: yup.number()
          .min(1, '1 ~ 99.9 사이로 입력해 주세요.')
          .max(99.9, '1 ~ 99.9 사이로 입력해 주세요.')
          .test('matches', '소수점 1자리까지만 입력해 주세요.', (value) => {
            if ( String(value).split('.')[1]?.length > 1 ) return false;
            return true;
          })
      })
    ),
    defaultValues: {
      volume: value
    }
  });

  const onSubmit = async (data: { volume?: number }) => {
    setLoading(true);
    await updateVolume(data.volume)
      .then(() => updateDatesets())
      .catch((error) => setMessage(error.message))
      .finally(() => {
        handleVolume();
        setLoading(false);
      });
  };

  return (
    <Dialog
      title='염소 주입'
      handleClose={handleVolume}
    >
      <form onSubmit={ handleSubmit(onSubmit) }>
        <TextField name='volume' control={control} label='염소 주입량 (ml)' type='number' step={0.1} min={0.5} max={105} />

        <ButtonWrapper>
          <PrimaryButton type='submit'>주입</PrimaryButton>
          <DefaultButton type='button' onClick={() => handleVolume()}>취소</DefaultButton>
        </ButtonWrapper>
      </form>
    </Dialog>
  );
}